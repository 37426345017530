import { apiSlice } from "./api";
import {
  getCommissionsApi,
  getCommissionsFxApi,
  postCommissionsApi,
  updateCommissionsApi,
  deleteCommissionsApi,
  postAssetCommissionApi,
  getAssetCommissionApi,
  updateAssetCommissionApi,
  getPairsCommissionApi,
  postCommissionsBulkApi,
  getCommissionsReportsApi,
  getPairsCommissionFxApi,
  postCommissionsFxApi,
  updateCommissionsFxApi,
  deleteCommissionsFxApi,
  postCommissionsFxBulkApi,
  getUsersCommissionsReportsApi,
} from "../components/constant/Api";

export const commissionApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCommission: builder.query({
      query: ({ userId }) => ({
        url: `${getCommissionsApi}/${userId}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    getCommissionFx: builder.query({
      query: ({ userId }) => ({
        url: `${getCommissionsFxApi}/${userId}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    getPairsCommission: builder.query({
      query: ({ userId }) => ({
        url: `${getPairsCommissionApi}/${userId}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    getPairsCommissionFx: builder.query({
      query: ({ userId }) => ({
        url: `${getPairsCommissionFxApi}/${userId}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    addCommission: builder.mutation({
      query: (post) => ({
        url: postCommissionsApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["commission"],
    }),
    addCommissionFx: builder.mutation({
      query: (post) => ({
        url: postCommissionsFxApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["commission"],
    }),
    addCommissionBulk: builder.mutation({
      query: (post) => ({
        url: postCommissionsBulkApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["commission"],
    }),
    addCommissionFxBulk: builder.mutation({
      query: (post) => ({
        url: postCommissionsFxBulkApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["commissionFx"],
    }),
    updateCommission: builder.mutation({
      query: (post) => ({
        url: updateCommissionsApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["commission"],
    }),
    deleteCommission: builder.mutation({
      query: (post) => ({
        url: deleteCommissionsApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["commission"],
    }),
    updateCommissionFx: builder.mutation({
      query: (post) => ({
        url: updateCommissionsFxApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["commissionFx"],
    }),
    deleteCommissionFx: builder.mutation({
      query: (post) => ({
        url: deleteCommissionsFxApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["commissionFx"],
    }),
    getAssetCommission: builder.query({
      query: ({ targetUserId, assetType }) => ({
        url: `${getAssetCommissionApi}/${targetUserId}/${assetType}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
      invalidatesTags: (_) => ["assetCommission"],
    }),
    addAssetCommission: builder.mutation({
      query: (post) => ({
        url: postAssetCommissionApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["assetCommission"],
    }),
    updateAssetCommission: builder.mutation({
      query: (post) => ({
        url: updateAssetCommissionApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["assetCommission"],
    }),
    getCommissionReports: builder.query({
      query: ({ type, segment }) => ({
        url: `${getCommissionsReportsApi}/${type}/${segment}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
      invalidatesTags: (_) => ["commissionReports"],
    }),
    getUsersCommissionReports: builder.query({
      query: ({ userId, segment }) => ({
        url: `${getUsersCommissionsReportsApi}/${userId}/${segment}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
      invalidatesTags: (_) => ["commissionReports"],
    }),
  }),
});

export const {
  useGetCommissionQuery,
  useGetCommissionFxQuery,
  useGetPairsCommissionQuery,
  useGetPairsCommissionFxQuery,
  useAddCommissionMutation,
  useUpdateCommissionMutation,
  useDeleteCommissionMutation,
  useGetAssetCommissionQuery,
  useAddAssetCommissionMutation,
  useUpdateAssetCommissionMutation,
  useAddCommissionBulkMutation,
  useGetCommissionReportsQuery,
  useAddCommissionFxMutation,
  useDeleteCommissionFxMutation,
  useUpdateCommissionFxMutation,
  useAddCommissionFxBulkMutation,
  useGetUsersCommissionReportsQuery,
} = commissionApi;
