import React from "react";
import TableLoader from "../../../widgets/TableLoader";
import { useGetUsersCommissionReportsQuery } from "../../../../redux/commissionApi";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import UsersCommissionTable from "../UsersCommissionTable";

const UserCommissionEquity = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);

  const { data, isLoading } = useGetUsersCommissionReportsQuery({
    userId: user.id,
    segment: "equity",
  });

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="d-md-flex mb-3">
            <h3 className="page-title">
              {t("Users Equity Commission Report")}
            </h3>
            <div className=" ms-auto"></div>
          </div>

          {isLoading ? (
            <TableLoader />
          ) : (
            <div className="white-box">
              <div className="table-responsive">
                <UsersCommissionTable data={data} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserCommissionEquity;
